<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-data-table :headers="headers" :items="skus" :search="search">
          <!-- outlet template -->
          <template v-slot:item.oitm="props">
            <v-btn
              color="flatButton"
              text
              :to="`/item-master-data/${props.item.oitm.id}`"
              >{{ props.item.oitm.ItemName }}</v-btn
            >
          </template>
          <!-- end -->
          <template v-slot:item.TargetValue>{{ TargetValue }}</template>
        </v-data-table>
      </v-col>
    </v-row>
    <snackbar refs="snackbar"></snackbar>
  </v-container>
</template>
    
    <script>
import { mapGetters } from "vuex";
export default {
  components: {},
  computed: {
    ...mapGetters(["user"]),
  },
  data: () => ({
    dataItem: {},
    search: null,
    targetData: [],
    SlpName: null,
    id: null,
    TargetValue: null,
    skus: [],
    editMode: false,
    headers: [
      { text: "Sku", value: "oitm.ItemName" },
      { text: "Item Code", value: "ItemCode" },
    ],
  }),
  watch: {
    "$route.params.id": {
      handler: "getTarget",
      immediate: true,
    },
  },
  methods: {
    getTarget(val) {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/get_targets_skus/${val}`)
        .then((res) => {
          console.log(res, "single target");
          self.skus = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {},
};
</script>